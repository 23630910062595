import React from 'react';
// import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import Footer from '../components/Footer';

import Sidebar from '../components/Sidebar';
import Scroll from '../components/Scroll';
import Projects from '../components/Projects';
import Experience from '../components/Experience';

const IndexPage = ({ data }) => (
  <Layout>
    <Sidebar />

    <div id="wrapper">
      <section id="intro" className="wrapper style1 fullscreen fade-up">
        <div className="inner">
          <h3> Hi, my name is </h3>
          <h1>Abdilaziz Ibrahim</h1>
          <h3> and I work as a Software Engineer. </h3>
          <p>
            I build quality software and learn a diverse set of technologies.
          </p>
          <p>
            If you are interested in some of the work I have done, you can
            checkout my &#8203;
            <Scroll type="id" element="projects">
              <a href="#projects">portfolio</a>
            </Scroll>
            .
          </p>
          <ul className="actions">
            <li>
              <Scroll type="id" element="contact">
                <a href="#contact" className="button">
                  Contact
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
      </section>

      <section id="about" className="wrapper style2 fullscreen fade-up">
        <div className="inner">
          <h2>About Me</h2>
          <div className="about-content">
            <div className="about-description">
              <p>
                I am a Software Engineer currently in the Toronto Area. I love
                working on a variety of tech/software related things, but I
                mostly work on the Full Stack of Web Applications.
                <br />
                <br />
                I have a Bachelors Degree in Electrical Enineering and
                Management from McMaster University, and am currently a part of
                the Engineering team at ComQi working on Digital Signage
                Solutions.
                <br />
                <br />
                Here are some technologies I have used recently:
              </p>
              <li>JavaScript</li>
              <li>HTML & CSS (SCSS)</li>
              <li>Angular and React</li>
              <li>Node.js and Express.js</li>
              <li>Java and Spring</li>
              <li>Mongoose and MongoDB</li>
              <li>MySQL</li>
              <li>GraphQL</li>
            </div>
            <div className="about-image">
              <Img
                fluid={data.allFile.nodes[0].childImageSharp.fluid}
                alt="Abdilaziz"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="experience" className="wrapper style3 fullscreen fade-up">
        <div className="inner">
          <h2>Experience</h2>
          <p>Here's a list of places I've worked:</p>
          <Experience />
        </div>
      </section>

      <section id="projects" className="wrapper style2">
        <div className="inner">
          <h2> Projects </h2>
          <p> Take a look at some projects I've worked on:</p>
          <Projects />
        </div>
      </section>

      <section id="contact" className="wrapper style3 fade-up">
        <div className="inner">
          <h2>Get in touch</h2>
          <p>
            If you would like to speak about new opportunities my inbox is
            always open.
          </p>
          <div className="style1">
            <section>
              <ul className="contact">
                <li>
                  <h3>Email</h3>
                  <a href="mailto:contact@abdilazizibrahim.dev">
                    contact@abdilazizibrahim.dev
                  </a>
                </li>
                <li>
                  <h3>Social</h3>
                  <ul className="icons">
                    <li>
                      <a
                        href="https://www.github.com/Abdilaziz"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fa-github"
                      >
                        <span className="label">GitHub</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/abdilaziz-ibrahim/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fa-linkedin"
                      >
                        <span className="label">LinkedIn</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { eq: "profile_photo" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
        name
      }
    }
  }
`;

export default IndexPage;
