import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box className="experience-panel" p={3}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Experience() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const tabs = [];
  const tabPanels = [];

  return (
    <StaticQuery
      query={graphql`
        {
          allStrapiExperience {
            edges {
              node {
                startDate
                points {
                  fields
                }
                location
                jobtitle
                endDate
                companyname
              }
            }
          }
        }
      `}
      render={data => {
        let experienceList = data.allStrapiExperience.edges;
        experienceList.map((position, i) => {
          tabs.push(
            <Tab
              key={'tab' + i}
              label={position.node.companyname}
              {...a11yProps(i)}
            />
          );
          tabPanels.push(
            <TabPanel key={'panel' + i} value={value} index={i}>
              <h3>
                {' '}
                {position.node.jobtitle} @ {position.node.companyname}
              </h3>
              <div>
                {' '}
                {position.node.startDate + ' - ' + position.node.endDate}
              </div>
              <ul>
                {position.node.points.fields.map((point, j) => (
                  <li key={'points' + i.toString() + j.toString()}>
                    {' '}
                    {point}{' '}
                  </li>
                ))}
              </ul>
            </TabPanel>
          );
        });
        return (
          <div className="experience-tabs">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Experience Section"
            >
              {tabs}
            </Tabs>

            <SwipeableViews
              axis={'y'}
              index={value}
              onChangeIndex={handleChangeIndex}
              autoPlay={false}
            >
              {tabPanels}
            </SwipeableViews>
          </div>
        );
      }}
    />
  );
}
