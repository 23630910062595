import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import MediaCard from './MediaCard';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = { viewAllProjects: false };

    // This binding is necessary to make `this` work in the callback
    this.handleViewMore = this.handleViewMore.bind(this);
  }

  handleViewMore() {
    this.setState(state => ({
      viewAllProjects: !state.viewAllProjects,
    }));
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allStrapiProject {
              totalCount
              edges {
                node {
                  id
                  imagetitle
                  image {
                    relativePath
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  name
                  location
                  order
                  description
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <div id="mediacards">
              {data.allStrapiProject.edges
                .sort((a, b) => {
                  return a.node.order - b.node.order;
                })
                // by default, if a project is to be shown, ensure it has an order less than 3
                .filter(project => {
                  return this.state.viewAllProjects || project.node.order <= 3;
                })
                .map((project, i) => {
                  return (
                    <MediaCard
                      key={i}
                      projectname={project.node.name}
                      projectdescription={project.node.description}
                      imgTitle={project.node.imagetitle}
                      img={project.node.image.childImageSharp.fluid}
                      location={project.node.location}
                    />
                  );
                })}
            </div>
            <a
              href={`#projects`}
              onClick={this.handleViewMore}
              className="button"
            >
              {this.state.viewAllProjects ? 'View Less' : 'View More'}
            </a>
          </>
        )}
      />
    );
  }
}

export default Projects;
