import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import Scroll from './Scroll';

import siteicon from '../assets/img/website-icon.png';

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { content: 'About', href: 'about' },
        { content: 'Experience', href: 'experience' },
        { content: 'Projects', href: 'projects' },
        { content: 'Contact', href: 'contact' },
      ],
    };
  }

  goHome(e) {
    e.preventDefault();
    window.location.href = '/#';
  }

  render() {
    const { tabs } = this.state;
    return (
      <section id="sidebar">
        <div className="inner">
          <img
            className="siteicon"
            src={siteicon}
            onClick={this.goHome}
            alt="Home Page"
          ></img>
          <nav>
            <Scrollspy
              items={tabs.map(s => s.href)}
              // currentClassName="active"
              offset={-300}
            >
              {tabs.map((tab, i) => {
                const { href, content } = tab;
                // const offset = (i === 0)? "3.5em": "";
                const offset = '';
                return (
                  <li key={href}>
                    <Scroll type="id" element={href} offset={offset}>
                      <a href={`#${href}`}>{content}</a>
                    </Scroll>
                  </li>
                );
              })}
            </Scrollspy>
          </nav>
        </div>
      </section>
    );
  }
}

export default Sidebar;
