import React, { Component } from 'react';
import Img from 'gatsby-image';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Reactmarkdown from 'react-markdown';

class MediaCard extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    let url = this.props.location;
    // regex experession for url validation.
    // eslint-disable-next-line
    let isValid = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(
      url
    );
    if (isValid) {
      window.open(
        this.props.location,
        '_blank' // <- This is what makes it open in a new window.
      );
    }
  }

  render() {
    return (
      <Card className="card">
        <CardActionArea className="card-actionarea" onClick={this.handleClick}>
          <Img
            className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img"
            fluid={this.props.img}
            alt={this.props.imgTitle}
          />
          <CardContent className="card-content">
            <h3> {this.props.projectname} </h3>
            <Reactmarkdown source={this.props.projectdescription} />
          </CardContent>
        </CardActionArea>
        {/* <CardActions className="card-actions" >
          <a href="/#" className="button">
            Learn More
          </a>
        </CardActions> */}
      </Card>
    );
  }
}

export default MediaCard;
